"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const tableSize = 12;
const random = (min, max) => Math.floor(Math.random() * (max + 1 - min)) + min;
const randomChoose = (choices) => {
    const index = Math.floor(Math.random() * choices.length);
    return choices[index];
};
class MultiplicationchartCtrl {
    constructor($scope, $timeout, ModalServiceFactory, ConfigService, SoundService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.ModalServiceFactory = ModalServiceFactory;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this._defaultSteps = 15;
        this._startTimer = new rxjs_1.Subject();
        this._stopTimer = new rxjs_1.Subject();
        this.startWith = 0;
        this.steps$ = new rxjs_1.Subject();
        this.numbers$ = new rxjs_1.Subject();
        this.start$ = new rxjs_1.Subject();
        this.multiple$ = new rxjs_1.Subject();
        this.game$ = (0, rxjs_1.merge)(this.steps$, this.numbers$.pipe((0, operators_1.map)((value) => {
            value.numbers = value.numbers.filter((item) => item.enabled);
            return value;
        })), this.start$);
        this.visible$ = (0, rxjs_1.fromEvent)(document, 'visibilitychange').pipe((0, operators_1.startWith)('visible'), (0, operators_1.map)(() => {
            return document.visibilityState;
        }));
        this._resume = new rxjs_1.BehaviorSubject('visible');
        this.steps = this._defaultSteps;
        this.currentStep = 1;
        this.canStart = true;
        this.start = false;
        this.multiplePair = [];
        this.numbers = [...Array(12).keys()]
            .filter((item) => item >= 1)
            .map((item) => ({ value: item + 1, enabled: true }));
        this.allNumbers = true;
        this.formSubmitting = false;
        this.timeRemaining = '00:00';
        this.timerWork = false;
        this.resultCell = undefined;
        this.matrix = [];
        this.point = { rowIndex: undefined, cellIndex: undefined };
        this.kind = 'table';
        this._startTimer.pipe((0, operators_1.tap)((value) => {
            if (!value) {
                this.timeRemaining = MultiplicationchartCtrl._formatTime(this.startWith);
            }
        }), (0, operators_1.distinctUntilChanged)(), (0, operators_1.switchMap)((value) => {
            if (value) {
                return this._makeClock();
            }
            return rxjs_1.EMPTY;
        })).subscribe();
    }
    $onInit() {
        for (const row_index of [...Array(tableSize).keys()]) {
            let row = [];
            for (const cell_index of [...Array(tableSize).keys()]) {
                const value = (row_index + 1) * (cell_index + 1);
                row.push({
                    value: value,
                    isSquare: value == Math.pow(row_index + 1, 2)
                });
            }
            this.matrix.push(row);
        }
    }
    $postLink() {
        const table = document.getElementsByClassName('widget__times');
        if (table) {
            (0, rxjs_1.fromEvent)(table, 'mouseover').pipe((0, operators_1.debounceTime)(10), (0, operators_1.tap)((e) => {
                const td = e.target;
                const tr = td.parentNode;
                this.$scope.$apply(() => {
                    if ((tr.rowIndex > 0) && (td.cellIndex > 0)) {
                        this.point = {
                            rowIndex: tr.rowIndex - 1,
                            cellIndex: td.cellIndex - 1
                        };
                        //@ts-ignore
                        this.resultCell = this.matrix[this.point.rowIndex][this.point.cellIndex];
                    }
                    else {
                        this.point = { rowIndex: undefined, cellIndex: undefined };
                        this.resultCell = undefined;
                    }
                });
            }), (0, operators_1.switchMap)(() => {
                return (0, rxjs_1.fromEvent)(table, 'mouseleave').pipe((0, operators_1.tap)((e) => {
                    this.$scope.$apply(() => {
                        this.point = { rowIndex: undefined, cellIndex: undefined };
                        this.resultCell = undefined;
                    });
                }));
            })).subscribe();
        }
        this.game$.pipe((0, operators_1.startWith)({
            steps: this.steps,
            numbers: [...this.numbers],
            start: this.start,
            currentStep: this.currentStep,
        }), (0, operators_1.scan)((state, curr) => (Object.assign(Object.assign({}, state), curr)), {}), (0, operators_1.filter)((state) => {
            // console.log(state)
            this.canStart = (state.steps >= 1) && (state.numbers.length >= 1);
            return this.canStart;
        }), (0, operators_1.switchMap)((state) => {
            this.start = state.start;
            if (state.start) {
                const input = document.getElementById('multiple');
                this.startWith = 0;
                this._startTimer.next(null);
                this._startTimer.next(true);
                const validNumbers = state.numbers.map((item) => item.value);
                const lastStepIndex = state.steps - 1;
                this.currentStep = 1;
                this.multiplePair = [
                    randomChoose(validNumbers),
                    random(2, 12)
                ];
                return (0, rxjs_1.from)([...Array(state.steps).keys()]).pipe((0, operators_1.concatMap)((step) => {
                    return this.multiple$.pipe((0, operators_1.switchMap)((value) => {
                        const answer = {
                            multiplePair: [...this.multiplePair],
                            value: value.multiple$.$modelValue,
                            right_value: this.multiplePair[0] * this.multiplePair[1]
                        };
                        this.formSubmitting = true;
                        return (0, rxjs_1.timer)(500).pipe((0, operators_1.switchMap)(() => {
                            if (answer.value == answer.right_value) {
                                return (0, rxjs_1.of)(answer);
                            }
                            else {
                                value.multiple$.$setViewValue(answer.right_value);
                                value.multiple$.$render();
                                return (0, rxjs_1.timer)(500);
                            }
                        }), (0, operators_1.finalize)(() => {
                            value.form$.$setPristine();
                            value.form$.$setUntouched();
                            value.multiple$.$setViewValue(undefined);
                            value.multiple$.$render();
                            this.$scope.$apply(() => {
                                this.formSubmitting = false;
                            });
                        }));
                    }), (0, operators_1.map)((answer) => {
                        if (step < lastStepIndex) {
                            this.currentStep++;
                            this.multiplePair = [
                                randomChoose(validNumbers),
                                random(2, 12)
                            ];
                            setTimeout(() => {
                                input.focus();
                            }, 100);
                        }
                        else {
                            this.multiplePair = [];
                        }
                        return answer;
                    }), (0, operators_1.first)());
                }), (0, operators_1.reduce)((state, curr) => [...state, curr], []));
            }
            else {
                this.multiplePair = [];
                this.currentStep = 1;
                return rxjs_1.NEVER;
            }
        }), (0, operators_1.tap)((total) => {
            this._endGame(total);
        })).subscribe();
    }
    changeSteps() {
        if (this.steps != undefined)
            this.steps$.next({ steps: this.steps });
    }
    changeNumbers() {
        this.numbers$.next({ numbers: this.numbers });
        this.allNumbers = this.numbers.filter((item) => item.enabled).length == this.numbers.length;
    }
    changeAllNumbers() {
        for (const number of this.numbers) {
            number.enabled = this.allNumbers;
        }
        this.numbers$.next({ numbers: this.numbers });
    }
    changeStepsBlur() {
        if (this.steps == undefined)
            this.steps = this._defaultSteps;
        this.steps$.next({ steps: this.steps });
    }
    onStart() {
        this.start$.next({ start: true });
    }
    submitGameForm(form$) {
        if (this.start) {
            const multiple$ = form$['multiple'];
            this.multiple$.next({ form$, multiple$ });
        }
    }
    _endGame(total) {
        this.SoundService.play('win');
        this._stopTimer.next(null);
        const rightAnswers = total.filter((item) => item.right_value);
        const rightAnswersPercent = Math.round(rightAnswers.length * 100 / total.length);
        const closeCallback = (result) => {
            this.start$.next({ 'start': false });
        };
        this.ModalServiceFactory.open({
            id: 'game_status',
            template: require("./end_game.ng.html"),
            component: "alert-comp",
            scope: this.$scope,
            extraContext: {
                show_timer: this.ConfigService.cookieSettings.show_timer,
                timeRemaining: this.timeRemaining,
                steps: total.length,
                rightAnswers: rightAnswers.length,
                rightAnswersPercent: rightAnswersPercent,
                incorrectAnswers: total.length - rightAnswers.length,
                incorrectAnswersPercent: 100 - rightAnswersPercent,
            }
        }).then((result) => {
            closeCallback(result);
        });
    }
    _makeClock() {
        return (0, rxjs_1.combineLatest)([this.visible$, this._resume]).pipe((0, operators_1.switchMap)(([v1, v2]) => {
            if ((v1 == 'visible') && (v2 == 'visible')) {
                return (0, rxjs_1.timer)(0, 1000).pipe((0, operators_1.withLatestFrom)((0, rxjs_1.of)(this.startWith)));
            }
            return rxjs_1.EMPTY;
        }), (0, operators_1.map)(([i, startWith]) => {
            return i + startWith;
        }), (0, operators_1.map)((sec) => {
            this.$timeout(() => {
                this.timerWork = true;
                this.startWith = sec;
                this.timeRemaining = MultiplicationchartCtrl._formatTime(sec);
            });
            return sec;
        }), (0, operators_1.takeUntil)(this._stopTimer), (0, operators_1.finalize)(() => {
            this.timerWork = false;
        }));
    }
    static _formatTime(sec) {
        if (sec >= 3600) {
            return new Date(sec * 1000).toISOString().substr(11, 8);
        }
        return new Date(sec * 1000).toISOString().substr(14, 5);
    }
}
MultiplicationchartCtrl.$inject = [
    '$scope',
    '$timeout',
    'ModalServiceFactory',
    'ConfigService',
    'SoundService',
];
const appModule = ng.module('app');
appModule.component('gameMultiplicationchart', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: MultiplicationchartCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.directive('mulValid', [() => {
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                mulValid: '=',
            },
            link: ($scope, $elem, $attrs, modelCtrl) => {
                modelCtrl.$validators.answer = (modelvalue, viewValue) => {
                    if (modelvalue != undefined)
                        return modelvalue == ($scope.mulValid[0] * $scope.mulValid[1]);
                    return true;
                };
            }
        };
    }]);
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('multiplicationchart/');
    }]);
